import React from 'react';

import type { Props } from './types';

import AppErrorBoundary from 'ui/shared/AppError/AppErrorBoundary';
// import HeaderAlert from 'ui/snippets/header/HeaderAlert';
import HeaderMobile from 'ui/snippets/header/HeaderMobile';

import * as Layout from './components';

const LayoutHome = ({ children }: Props) => {
  return (
    <Layout.Container>
      <Layout.TopRow/>
      <HeaderMobile isHomePage/>
      <Layout.MainArea>
        <Layout.SideBar/>
        <Layout.MainColumn paddingTop={{ base: 6, lg: 9 }}>
          { /* <HeaderAlert/> */ }
          <AppErrorBoundary>{ children }</AppErrorBoundary>
        </Layout.MainColumn>
      </Layout.MainArea>
      <Layout.Footer/>
    </Layout.Container>
  );
};

export default LayoutHome;
